.App {
  text-align: center;
}

.centred {
  display: flex;
  justify-content: center;
}

.formwidth {
  width: 650px;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
