.Tilt {
  background: linear-gradient(80deg, #369 0%, #123 100%);
  height: 100px;
  width: 100px;
}

.TiltImg {
  height: 70px;
  width: 80px;
}
